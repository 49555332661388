import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@cjm/shared/i18n';

export const RedirectI18nKeys = {
	...sharedI18nKeys,
	AuthenticationFailed: {
		Failed: {
			Title: marker('authentication-failed.failed.title'),
			Text: marker('authentication-failed.failed.text'),
			Button: marker('authentication-failed.failed.button')
		},
		LoginThreshholdExceeded: {
			Title: marker('authentication-failed.login-threshhold-exceeded.title'),
			Text: marker('authentication-failed.login-threshhold-exceeded.text'),
			Button: marker('authentication-failed.login-threshhold-exceeded.button')
		},
		Invalid: {
			Title: marker('authentication-failed.invalid.title'),
			Text: marker('authentication-failed.invalid.text'),
			Button: marker('authentication-failed.invalid.button')
		}
	},
	Maintenance: {
		Title: marker('maintenance.title'),
		Subtitle: marker('maintenance.subtitle'),
		Text: marker('maintenance.text'),
		Button: {
			Title: marker('maintenance.button.title'),
			Text: marker('maintenance.button.text')
		},
		Update: {
			Title: marker('maintenance.update.title'),
			Text: marker('maintenance.update.text'),
			Continue: marker('maintenance.update.continue'),
			ReadUpdates: marker('maintenance.update.read-updates')
		}
	},
	Disconnected: {
		Title: marker('disconnected.title'),
		Subtitle: marker('disconnected.subtitle'),
		Text: marker('disconnected.text'),
		Button: {
			Title: marker('disconnected.button.title'),
			Text: marker('disconnected.button.text')
		}
	},
	NotFound: {
		Title: marker('not-found.title'),
		Subtitle: marker('not-found.subtitle'),
		Text: marker('not-found.text'),
		Button: {
			Title: marker('not-found.button.title'),
			Text: marker('not-found.button.text')
		}
	},
	SomethingWentWrong: {
		Title: marker('something-went-wrong.title'),
		Subtitle: marker('something-went-wrong.subtitle'),
		Text: marker('something-went-wrong.text'),
		Button: {
			Title: marker('something-went-wrong.button.title'),
			Text: marker('something-went-wrong.button.text')
		}
	},
	InvitationError: {
		Title: marker('invitation-error.title'),
		Subtitle: marker('invitation-error.subtitle'),
		Text: marker('invitation-error.text')
	},
	Stop: {
		REQUEST: {
			MAX_ATTEMPTS: {
				Title: marker('stop.request.max-attempts.title'),
				Text: marker('stop.request.max-attempts.text'),
				NextSteps: {
					Title: marker('stop.request.max-attempts.next-steps.title'),
					Description: marker('stop.request.max-attempts.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.request.max-attempts.primary-button.title'),
					Text: marker('stop.request.max-attempts.primary-button.text')
				},
				SecondaryButton: null
			},
			NO_CONTACTS: {
				Title: marker('stop.request.no-contacts.title'),
				Text: marker('stop.request.no-contacts.text'),
				NextSteps: {
					Title: marker('stop.request.no-contacts.next-steps.title'),
					Description: marker('stop.request.no-contacts.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.request.no-contacts.primary-button.title'),
					Text: marker('stop.request.no-contacts.primary-button.text')
				},
				SecondaryButton: null
			},
			NOT_FOUND: {
				Title: marker('stop.request.not-found.title'),
				Text: marker('stop.request.not-found.text'),
				NextSteps: {
					Title: marker('stop.request.not-found.next-steps.title'),
					Description: marker('stop.request.not-found.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.request.not-found.primary-button.title'),
					Text: marker('stop.request.not-found.primary-button.text')
				},
				SecondaryButton: {
					Title: marker('stop.request.not-found.secondary-button.title'),
					Text: marker('stop.request.not-found.secondary-button.text')
				}
			},
			INCORRECT_USER: {
				Title: marker('stop.request.incorrect-user.title'),
				Text: marker('stop.request.incorrect-user.text'),
				NextSteps: {
					Title: marker('stop.request.incorrect-user.next-steps.title'),
					Description: marker('stop.request.incorrect-user.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.request.incorrect-user.primary-button.title'),
					Text: marker('stop.request.incorrect-user.primary-button.text')
				},
				SecondaryButton: {
					Title: marker('stop.request.incorrect-user.secondary-button.title'),
					Text: marker('stop.request.incorrect-user.secondary-button.text')
				}
			},
			KBO: {
				Title: marker('stop.request.kbo.title'),
				Text: marker('stop.request.kbo.text'),
				NextSteps: {
					Title: marker('stop.request.kbo.next-steps.title'),
					Description: marker('stop.request.kbo.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.request.kbo.primary-button.title'),
					Text: marker('stop.request.kbo.primary-button.text')
				},
				SecondaryButton: {
					Title: marker('stop.request.kbo.secondary-button.title'),
					Text: marker('stop.request.kbo.secondary-button.text')
				}
			}
		},
		REGISTRATION: {
			DEFAULT: {
				Title: marker('stop.registration.default.title'),
				Text: marker('stop.registration.default.text'),
				NextSteps: {
					Title: marker('stop.registration.default.next-steps.title'),
					Description: marker('stop.registration.default.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.default.primary-button.title'),
					Text: marker('stop.registration.default.primary-button.text')
				},
				SecondaryButton: null
			},
			FV_NOT_BUR: {
				Title: marker('stop.registration.fv-not-bur.title'),
				Text: marker('stop.registration.fv-not-bur.text'),
				NextSteps: {
					Title: marker('stop.registration.fv-not-bur.next-steps.title'),
					Description: marker('stop.registration.fv-not-bur.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.fv-not-bur.primary-button.title'),
					Text: marker('stop.registration.fv-not-bur.primary-button.text')
				},
				SecondaryButton: null
			},
			EA_AS_BUR: {
				Title: marker('stop.registration.ea-as-bur.title'),
				Text: marker('stop.registration.ea-as-bur.text'),
				NextSteps: {
					Title: marker('stop.registration.ea-as-bur.next-steps.title'),
					Description: marker('stop.registration.ea-as-bur.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.ea-as-bur.primary-button.title'),
					Text: marker('stop.registration.ea-as-bur.primary-button.text')
				},
				SecondaryButton: null
			},
			EA_AS_FV: {
				Title: marker('stop.registration.ea-as-fv.title'),
				Text: marker('stop.registration.ea-as-fv.text'),
				NextSteps: {
					Title: marker('stop.registration.ea-as-fv.next-steps.title'),
					Description: marker('stop.registration.ea-as-fv.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.ea-as-fv.primary-button.title'),
					Text: marker('stop.registration.ea-as-fv.primary-button.text')
				},
				SecondaryButton: {
					Title: marker('stop.registration.ea-as-fv.secondary-button.title'),
					Text: marker('stop.registration.ea-as-fv.secondary-button.text')
				}
			},
			EA_AS_REGISTERED: {
				Title: marker('stop.registration.ea-as-registered.title'),
				Text: marker('stop.registration.ea-as-registered.text'),
				NextSteps: {
					Title: marker('stop.registration.ea-as-registered.next-steps.title'),
					Description: marker('stop.registration.ea-as-registered.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.ea-as-registered.primary-button.title'),
					Text: marker('stop.registration.ea-as-registered.primary-button.text')
				},
				SecondaryButton: {
					Title: marker('stop.registration.ea-as-registered.secondary-button.title'),
					Text: marker('stop.registration.ea-as-registered.secondary-button.text')
				}
			},
			EA_AS_NOT_REGISTRABLE: {
				Title: marker('stop.registration.ea-as-not-registrable.title'),
				Text: marker('stop.registration.ea-as-not-registrable.text'),
				NextSteps: {
					Title: marker('stop.registration.ea-as-not-registrable.next-steps.title'),
					Description: marker('stop.registration.ea-as-not-registrable.next-steps.description')
				},
				PrimaryButton: {
					Title: marker('stop.registration.ea-as-not-registrable.primary-button.title'),
					Text: marker('stop.registration.ea-as-not-registrable.primary-button.text')
				},
				SecondaryButton: null
			}
		}
	}
};
