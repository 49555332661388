<cjm-content [loading]="!errorBody()">
	<cjm-layout-container
		classesMain="p-stop__main-container"
		classesAside="p-stop__aside-container"
		layoutType="split-equal"
		asidePosition="right"
		alignItems="start"
		preventSpacing="bottom"
	>
		<ng-container main>
			<h1 class="c-vloket-header c-vloket-header--h1">
				<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
					{{ errorBody().title | translate }}
				</span>
			</h1>

			<div
				[innerHTML]="
					errorBody().body
						| translate: { associationName: data()?.associationName }
						| withRouterLinks
							: [
									{
										dataLinkId: 'legalAdvice',
										link: [
											i18nService.currentLanguage,
											vloketAppRoutes.Advice,
											'juridische_vorm_vereniging'
										]
									}
							  ]
				"
			></div>

			@if (errorBody().nextSteps.title; as title) {
				<h2 class="c-vloket-header c-vloket-header--h4 u-margin-top-xl">
					<span class="c-vloket-text-marking c-vloket-text-marking--underline">
						{{ title | translate }}
					</span>
				</h2>
			}

			<div>
				@if (errorBody().nextSteps.description; as description) {
					<p class="u-margin-bottom-lg" [innerHTML]="description | translate"></p>
				}

				<div class="p-stop__actions">
					@for (button of errorBody().buttons; track button.link) {
						@if (button.type === 'AUTH' && button.link; as buttonLink) {
							<button
								cjmUserEIDIdentitySwitch
								[title]="button.title | translate"
								[callback]="buttonLink"
								[class]="
									button.primary ? buttonClasses.LinkButton : buttonClasses.LinkButtonOutlineSolid
								"
							>
								{{ button.text | translate }}
							</button>
						}
						@if (button.type === 'REDIRECT' && button.link; as buttonLink) {
							<cjm-link
								[title]="button.title | translate"
								[to]="buttonLink"
								[classes]="
									button.primary ? buttonClasses.LinkButton : buttonClasses.LinkButtonOutlineSolid
								"
								>{{ button.text | translate }}</cjm-link
							>
						}
					}
				</div>
			</div>
		</ng-container>

		<div class="p-stop__visual" asideRight>
			<cjm-img src="img/stop-page-image.svg" />
		</div>
	</cjm-layout-container>
</cjm-content>
