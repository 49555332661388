import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { WithRouterLinkPipe } from '@studiohyperdrive/ngx-utils';
import { tap } from 'rxjs';

import { UserEIDIdentitySwitchDirective } from '@cjm/shared/authentication/auth';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { ACMTargetGroups } from '@cjm/shared/types';
import {
	ButtonClasses,
	CJMContentComponent,
	ImageComponent,
	LayoutContainerComponent,
	LinkComponent
} from '@cjm/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

import { STOP_CASES, STOP_CODES } from './stop.page.const';
import { StopErrorBody, StopPageDataParam, StopPageQueryParams } from './stop.types';

@Component({
	standalone: true,
	templateUrl: './stop.page.html',
	styleUrls: ['./stop.page.scss'],
	host: {
		class: 'p-stop'
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CJMContentComponent,
		LayoutContainerComponent,
		UserEIDIdentitySwitchDirective,
		LinkComponent,
		ImageComponent,
		TranslateModule,
		WithRouterLinkPipe
	]
})
export class StopPageComponent implements OnInit {
	public readonly errorBody: WritableSignal<StopErrorBody> = signal(null);
	public readonly data: WritableSignal<StopPageDataParam> = signal(null);
	public readonly i18nKeys: typeof RedirectI18nKeys = RedirectI18nKeys;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly callbackUrlAfterIdetitySwitch: string = `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.EARegistrationForm}`;
	public readonly acmTargetGroups: typeof ACMTargetGroups = ACMTargetGroups;
	public readonly vloketAppRoutes: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(
		public readonly i18nService: I18nService,
		private readonly activatedRoute: ActivatedRoute
	) {}

	public ngOnInit(): void {
		this.activatedRoute.queryParams
			.pipe(
				tap((params: StopPageQueryParams) => {
					try {
						this.data.set(JSON.parse(params.data));
					} catch {
						this.data.set(null);
					}

					this.errorBody.set(
						this.parseErrorBody({
							stopCause: params.cause,
							stopCase: params.case
						})
					);
				})
			)
			.subscribe();
	}

	private parseErrorBody({
		stopCause = STOP_CODES.REGISTRATION,
		stopCase = STOP_CASES.DEFAULT
	}: {
		stopCause: STOP_CODES;
		stopCase: STOP_CASES;
	}): StopErrorBody {
		const { type: primaryButtonType, link: primaryButtonLink } = this.parsePrimaryButtonLink(stopCause, stopCase);
		const { type: secondaryButtonType, link: secondaryButtonLink } = this.parseSecondaryButtonLink(
			stopCause,
			stopCase
		);

		return {
			title: this.i18nKeys.Stop[stopCause][stopCase].Title,
			nextSteps: {
				title: this.i18nKeys.Stop[stopCause][stopCase].NextSteps.Title,
				description: this.i18nKeys.Stop[stopCause][stopCase].NextSteps.Description
			},
			body: this.i18nKeys.Stop[stopCause][stopCase].Text,
			buttons: [
				{
					type: primaryButtonType,
					link: primaryButtonLink,
					text: this.i18nKeys.Stop[stopCause][stopCase].PrimaryButton.Text,
					title: this.i18nKeys.Stop[stopCause][stopCase].PrimaryButton.Title,
					primary: true
				},
				// Denis: The secondary button is optional, so we need to check if it exists before adding it to the array
				...(this.i18nKeys.Stop[stopCause][stopCase].SecondaryButton
					? [
							{
								type: secondaryButtonType,
								link: secondaryButtonLink,
								text: this.i18nKeys.Stop[stopCause][stopCase].SecondaryButton.Text,
								title: this.i18nKeys.Stop[stopCause][stopCase].SecondaryButton.Title,
								primary: false
							}
						]
					: [])
			]
		};
	}

	/**
	 * parsePrimaryButtonLink
	 *
	 * The parsePrimaryButtonLink method will parse the primary button type & link based on the stopCause.
	 *
	 * @param stopCause{STOP_CODES} - The stop cause.
	 * @param stopCase{STOP_CASES} - The stop case.
	 * @returns{object} - The type and link of the primary button.
	 */
	public parsePrimaryButtonLink(
		stopCause: STOP_CODES,
		stopCase: STOP_CASES
	): {
		type: 'AUTH' | 'REDIRECT';
		link: string;
	} {
		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_BUR) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/${ACMTargetGroups.EA}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.FV_NOT_BUR) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/${ACMTargetGroups.BUR}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_FV) {
			return {
				type: 'REDIRECT',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.MyAssociation}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_REGISTERED) {
			return {
				type: 'REDIRECT',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.MyAssociation}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_NOT_REGISTRABLE) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/${ACMTargetGroups.BUR}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationStart}`
			};
		}

		return {
			type: 'REDIRECT',
			link: '/'
		};
	}

	/**
	 * parseSecondaryButtonLink
	 *
	 * The parseSecondaryButtonLink method will decide the secondary button type & link based on the stopCause.
	 *
	 * @param stopCause{STOP_CODES} - The stop cause.
	 * @param stopCase{STOP_CASES} - The stop case.
	 * @returns{object} - The type and link of the primary button.
	 */
	public parseSecondaryButtonLink(
		stopCause: STOP_CODES,
		stopCase: STOP_CASES
	): {
		type: 'AUTH' | 'REDIRECT';
		link: string;
	} {
		if (
			stopCause === STOP_CODES.REQUEST &&
			(stopCase === STOP_CASES.NOT_FOUND || stopCase === STOP_CASES.INCORRECT_USER)
		) {
			return {
				type: 'REDIRECT',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Associations}`
			};
		}

		if (stopCause === STOP_CODES.REQUEST && STOP_CASES.KBO) {
			return {
				type: 'REDIRECT',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Advice}/vertegenwoordigers_in_het_verenigingsloket`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_FV) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/${ACMTargetGroups.EA}`
			};
		}

		if (stopCause === STOP_CODES.REGISTRATION && stopCase === STOP_CASES.EA_AS_REGISTERED) {
			return {
				type: 'AUTH',
				link: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/${ACMTargetGroups.EA}`
			};
		}

		return {
			type: null,
			link: null
		};
	}
}
